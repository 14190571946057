import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/nextjs"
import * as api from "./onboardingRecommendationsAPI"
import { OnboardingRecommendationsState } from "./types"

const initialState: OnboardingRecommendationsState = {
  recommendations: null,
  monteCarloSimulation: null,
  isRecommendationsLoading: false,
  isMonteCarloLoading: false,
  strategy: {},
}

export const fetchRecommendations = createAsyncThunk(
  "onboardingRecommendations/fetchRecommendations",
  api.fetchRecommendations
)
export const fetchMonteCarlo = createAsyncThunk("onboardingRecommendations/fetchMonteCarlo", api.fetchMonteCarlo)

export const onboardingRecommendationsSlice = createSlice({
  name: "onboardingRecommendations",
  initialState,
  reducers: {
    setPercentageToSell: (state, action: PayloadAction<number>) => {
      state.strategy.percentageToSell = action.payload
    },
    setFutureRsusOnly: (state, action: PayloadAction<boolean>) => {
      state.strategy.futureRsusOnly = action.payload
    },
    setSeasoningPeriod: (state, action: PayloadAction<number>) => {
      state.strategy.seasoningPeriod = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommendations.pending, (state) => {
        state.isRecommendationsLoading = true
      })
      .addCase(fetchMonteCarlo.pending, (state) => {
        state.isMonteCarloLoading = true
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.recommendations = payload.recommendations
        }
        state.isRecommendationsLoading = false
        if (!state.strategy.percentageToSell && state.strategy.percentageToSell !== 0) {
          state.strategy.percentageToSell = payload.recommendations.recommendedPercentageToSell
          state.strategy.percentageToSellFuture = payload.recommendations.recommendedPercentageToSellFuture
          state.strategy.recommendedNetWorthPercentage = payload.recommendations.recommendedNetWorthPercentage
        }
        if (!state.strategy.futureRsusOnly) {
          state.strategy.futureRsusOnly = payload.recommendations.recommendFutureOnly
        }
        if (payload.recommendations.priceLimit?.priceTarget) {
          state.strategy.priceTarget = payload.recommendations.priceLimit.priceTarget
          state.strategy.selloffTradeCount = 20
          state.strategy.selloffSpacingDays = 1
        } else {
          state.strategy.priceTarget = undefined
          state.strategy.selloffTradeCount = 12
          state.strategy.selloffSpacingDays = 7
        }
        if (payload.recommendations.recommend10b51Plan === false) {
          state.strategy.seasoningPeriod = 0
        }
        state.strategy.is10b51 = payload.recommendations.recommend10b51Plan
        state.strategy.washSale = payload.recommendations.recommendWashSale
        return state
      })
      .addCase(fetchMonteCarlo.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.monteCarloSimulation = payload.monteCarloSimulation
        }
        state.isMonteCarloLoading = false
        return state
      })
      .addCase(fetchRecommendations.rejected, (_, action) => {
        Sentry.captureException("Failed to fetch onboarding recommendations", {
          extra: {
            error: action.error,
          },
        })
      })
      .addCase(fetchMonteCarlo.rejected, (_, action) => {
        Sentry.captureException("Failed to fetch monte carlo simulation", {
          extra: {
            error: action.error,
          },
        })
      })
  },
})

export default onboardingRecommendationsSlice.reducer
