/**
 * @fileOverview Helpers for site analytics.
 * @author David Chouinard (david@candor.co)
 * @version 1.0
 */
import amplitude from "amplitude-js"
import * as Sentry from "@sentry/nextjs"

// Used to identity a user for analytics (this is called automatically when a user is logged in)
// Optionally returns the payload that was sent to analytics
function identify(user: Record<string, any>) {
  if (!window) return

  if (!user) {
    // if null or undefined is passed, set to an empty object
    user = {}
  }

  //
  // People pass all sort of junk in the user object, first sanitize it
  // and then standardize it to a consistent format
  //

  const payload: Record<string, string> = {}

  // Standardize email
  if (user.primaryEmailAddress) {
    // a Clerk user was passsed in
    payload.email = user.primaryEmailAddress.emailAddress
  } else if (user.email) {
    // Something else was passed in
    payload.email = user.email.trim().toLowerCase()
  }

  // Standardize name
  if (user.firstName) {
    // a Clerk user was passsed in
    payload.firstName = user.firstName
  } else if (user.givenName) {
    payload.firstName = user.givenName
  }

  if (user.lastName) {
    // a Clerk user was passsed in
    payload.lastName = user.lastName
  } else if (user.familyName) {
    // An onboarding "details" object was passed in
    payload.lastName = user.familyName
  }

  // Standardize company
  if (user.company) {
    if (typeof user.company === "string") {
      payload.company = user.company
    } else if ("label" in user.company) {
      // An onboarding "details" object was passed in
      payload.company = user.company.label
    }
  }

  // Standardize phone number
  if (user.primaryPhoneNumber) {
    payload.phoneNumber = user.primaryPhoneNumber.phoneNumber
  } else if (user.phoneNumber && typeof user.phoneNumber === "string") {
    payload.phoneNumber = user.phoneNumber
  }

  // Brokerage
  if (user.brokerage) {
    payload.brokerage = user.brokerage.toLowerCase()
  } else if (user.institution) {
    payload.brokerage = user.institution.toLowerCase()
  }

  if (user.is_eligible_for_brokerageless !== undefined) {
    payload.is_eligible_for_brokerageless = user.is_eligible_for_brokerageless
  }

  if (user.id) {
    // If an id is set, it's a Clerk ID, use that as the user ID
    payload.id = user.id
  }

  // If the user is logged in, use the user's information
  // @ts-expect-error: Typescript doesn't know that the 'Clerk' object exists on window
  if (window.Clerk && window.Clerk.user) {
    // @ts-expect-error: Typescript doesn't know that the 'Clerk' object exists on window
    const clerkUser = window.Clerk.user

    payload.id = clerkUser.id
    payload.email = clerkUser.primaryEmailAddress.emailAddress
  }

  if (window && window.localStorage?.getItem("landingPage")) {
    // Keep track of how we acquired the user, lowecase to match other properties in Amplitude
    payload.intial_landing_page = window.localStorage?.getItem("landingPage")
  }

  if (!payload || Object.keys(payload).length === 0) {
    // If no payload was generated, don't send anything
    return null
  }

  //
  // Send to the various analytics librairies
  //

  if (window.analytics) {
    if (payload.id) {
      window.analytics.identify(payload.id, payload)
    } else {
      window.analytics.identify(payload)
    }
  }

  if (amplitude && process.env.AMPLITUDE_API_KEY) {
    if (payload.id) {
      amplitude.getInstance().setUserId(payload.id)
    }
    amplitude.getInstance().setUserProperties(payload)
  }

  if (window.gtag) {
    if (payload.id) {
      // For Google Analytics specificly, set the user ID as the "user_id" (reserved keyword)
      window.gtag("set", "user_properties", { user_id: payload.id, ...payload })
    } else {
      window.gtag("set", "user_properties", payload)
    }
  }

  Sentry.setUser(payload)

  return payload
}

// Call this function to track an event in our various analytics services
// The event needs to be tied to a user (i.e. someone with an email address). Two options:
//   1. If the user is logged in, it will automatically use information for the logged in user
//   2. Otherwise, pass in a user object (must have at least an email address)
// Also, adblockers block most of these calls. By default, an event will also be sent to our api endpoint
// that will attempt to circumvent adblockers. Pass sendServerside = false to disable this.
function track(
  event: string,
  options: {
    user?: Record<string, any>
    eventProperties?: Record<string, string>
    eventValue?: number
    sendServerSide?: boolean
  } = {}
) {
  // First call identify() to make sure we can tie the analytics event to a user
  const userPayload = identify(options.user)

  if (window.analytics) {
    window.analytics.track(event, options.eventProperties)
  }

  if (amplitude && process.env.AMPLITUDE_API_KEY) {
    amplitude.getInstance().logEvent(event, options?.eventProperties)
  }

  if (window.gtag) {
    const parameters: any = { event_category: "All" }

    if (options && options.eventValue) {
      parameters.value = options.eventValue
    }

    // Google Analytics wants event names to be in snake_case
    const snakeCaseEvent = event.replace(/ /g, "_").replace(/-/g, "_").toLowerCase()
    window.gtag("event", snakeCaseEvent, parameters)

    if (event === "Onboarding initiated") {
      window.gtag("event", "conversion", { send_to: "AW-759299658/xPY6CLr7h5cDEMr8h-oC" })
    } else if (event === "Onboarding completed first part") {
      window.gtag("event", "conversion", { send_to: "AW-759299658/yKXECPmcgJcDEMr8h-oC" })
    } else if (event === "Chronos waitlist initiated") {
      window.gtag("event", "conversion", { send_to: "AW-759299658/tjbKCK6Ip8ADEMr8h-oC" })
    } else if (event === "Webinar registration") {
      window.gtag("event", "conversion", { send_to: "AW-759299658/5ilZCPqxjOYBEMr8h-oC" })
    } else if (event === "Newsletter sign up") {
      window.gtag("event", "conversion", { send_to: "AW-759299658/42xPCITL3PABEMr8h-oC" })
    } else if (event === "Issuer lead submitted") {
      window.gtag("event", "conversion", { send_to: "AW-759299658/gMPbCJfp6qYDEMr8h-oC" })
    }
  }

  if (window.gp) {
    if (event === "Course purchase initiated") {
      window.qp("track", "InitiateCheckout")
    }
  }

  if (window.fbq) {
    if (event === "Onboarding initiated") {
      window.fbq("track", "InitiateCheckout", options?.eventProperties)
    } else if (event === "Onboarding completed first part") {
      window.fbq("track", "CompleteRegistration", options?.eventProperties)
    } else if (event === "Issuer lead submitted" || event === "Issuer course access requested") {
      window.fbq("track", "Lead", options?.eventProperties)
    } else {
      window.fbq("trackCustom", event, options?.eventProperties)
    }
  }

  if (window.ttq) {
    if (event === "Onboarding initiated") {
      window.ttq.track("InitiateCheckout", options?.eventProperties)
    } else if (event === "Onboarding completed first part") {
      window.ttq.track("CompleteRegistration", options?.eventProperties)
    }
  }

  if (window.rdt) {
    if (event === "Onboarding initiated") {
      window.rdt("track", "Lead")
    } else if (event === "Onboarding completed first part") {
      window.rdt("track", "Sign Up")
    }
  }

  // Send to server-side unless explicitly told not to
  if (options.sendServerSide !== false && userPayload) {
    // execute asynchronously
    fetch("/api/event", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user: userPayload,
        eventProperties: options?.eventProperties,
        event,
      }),
    })
      .then(() => {})
      .catch(() => {})
  }
}

function page(path) {
  if (amplitude && process.env.AMPLITUDE_API_KEY) {
    amplitude.getInstance().logEvent("Loaded a Page", { path })
  }
  if (window.gtag && process.env.NODE_ENV === "production" && process.env.NEXT_PUBLIC_APP_ENV !== "integration") {
    // Only needed for legacy "Universal Analytics" tracking
    const GA_UA_TRACKING_ID = "UA-69562328-4"
    window.gtag("config", GA_UA_TRACKING_ID, { page_path: path })
  }
}

export default { identify, track, page }
