import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/nextjs"
import * as api from "./onboardingBrokerageAPI"
import { OnboardingQuizState } from "./types"

export const fetchBrokerageAccount = createAsyncThunk(
  "onboardingBrokerage/fetchBrokerageAccount",
  api.getWealthBrokerageAccount
)

const initialState: OnboardingQuizState = {
  wealthBrokerageAccount: null,
  isLoading: false,
}

export const onboardingBrokerageSlice = createSlice({
  name: "onboardingBrokerage",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setLoadingBrokerageAccount: (state, action: PayloadAction<string>) => {
      state.wealthBrokerageAccount = {
        ...state.wealthBrokerageAccount,
        status: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrokerageAccount.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBrokerageAccount.fulfilled, (state, action) => {
        const { payload } = action
        if (payload) {
          state.wealthBrokerageAccount = payload.brokerageAccount
        }
        state.isLoading = false
        return state
      })
      .addCase(fetchBrokerageAccount.rejected, (_, action) => {
        Sentry.captureException(`Failed to fetch wealth brokerage account: ${action.error}`)
      })
  },
})

export const { setIsLoading, setLoadingBrokerageAccount } = onboardingBrokerageSlice.actions

export default onboardingBrokerageSlice.reducer
